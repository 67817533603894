.main-container-editprogram {
  button {
    border: none;
    font-size: 1.5rem;
  }

  .button-back-programi {
    font-size: 1.2rem;
    margin-top: 1.8rem;

    svg {
      font-size: 1.3rem;
    }
  }
}

.programs-card-title-text {
  color: $primary-font-color;
}

.programs-preview-structure {
  opacity: 0.6;
}

.main-container-card__shadows {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.programs-structure-card-aktiven-text {
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 0.8rem;
  opacity: 0.8;
  cursor: pointer;

}

.card-text--activated {
  background: seagreen;
  color: white;
}

.card-text--deactivated {
  background: lightgrey;
}


.main-header {
  font-size: $header-text-size;
  margin-top: 1.28rem;
}

.header-divider {
  hr {
    margin-top: 0;
    border-top: 5px solid rgba(0, 0, 0, 0.1);
  }
}

.programs-button-add-text {
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

.programs-margin-left {
  margin-left: 8rem;
}

.program-button-edit {
  color: $color-white;

  &:hover {
    text-decoration: none;
    color: $color-white;
  }

  svg {
    font-size: 1.2rem;
  }
}

.program-structure-naslov__hover {
  &:hover {
    cursor: pointer;
  }
}

.programs-tree-button {
  border: none;

  svg {
    font-size: 1.2rem;
  }
}

.programs-checkbox {
  margin-top: 7px;
}


.projects-search-bar {
  form {
    width: 60%;
  }

  input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ced4da;
    padding-left: 0.2rem;
  }

  span {
    border: none;
    border-bottom: 1px solid #ced4da;
    background: none;
  }
}

.razpisi-button-details-text {
  &:hover {
    color: $primary-font-color;
    text-decoration: none;
  }
}


.row-header-izpostavljeni-razpisi {
  padding: 15px 0 15px 0;
  background-color: #2CB0AE;
  color: white;
  margin: auto;

  .row-header-izpostavljeni-razpisi-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.row-izpostavljeni-razpisi-text {
  margin: auto;
}


.row-header-razpisi-avtomatski {
  padding: 15px 0 15px 0;
  background-color: $color-um-primary;
  color: white;
  margin: auto;

  .row-header-avtomatski-razpisi-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

}

.row-header-razpisi-avtomatski-drugi-nivo {
  padding: 15px 0 15px 0;
  background-color: #ededed;
  margin: auto;

  .row-header-razpisi-avtomatski-drugi-nivo-title {
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.row-header-razpisi-avtomatski-tretji-nivo {
  margin: auto;

  .row-header-razpisi-avtomatski-drugi-nivo-title {
    font-size: 1.1rem;
    font-weight: bold;
  }
}




.avtorizacije-button-trash {
  border: none;
  background: none;

  &:hover {
    background: none;
    border: none;
  }

  ;

  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.org-button-details-text {
  border: none;
  background: none;
  color: $color-um-primary;

  &:hover {
    background: none;
    color: $primary-font-color;
  }
}

.org-button-details-text-status {
  @extend .org-button-details-text;
}

.project-row-status {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  margin: 0 0px;
}




.razpis-card {
  padding-top: 0rem;
  font-size: 1.1rem;

  .razpisDetails-header {
    font-size: 1.4rem;
    margin-top: 1.28rem;
    border-left: 10px solid $color-border;
  }

  span {
    color: gray;
  }
}

.button-back-razpisi {
  font-size: 1.2rem;
  margin-top: 1.8rem;

  svg {
    font-size: 1.3rem;
  }
}

.card-height {
  height: 100%;
}

table {
  color: $primary-font-color !important;
}

.project-partners-details-card {
  font-size: 1.1rem;

  span {
    color: gray;
  }
}

.project-partner-details-structure-naslov__hover {
  &:hover {
    cursor: pointer;
  }
}

.kontaktna-oseba-details-structure-naslov__hover {
  &:hover {
    cursor: pointer;
  }
}

.project-partner-kontakt-background {
  background-color: rgba(0, 0, 0, 0.02);
}

.custom-top-margin {
  margin-top: 30px;
}

.nav-link.active {
  background: rgba(0, 144, 193, 0.85) !important;
  color: white !important;
}

.tooltip-icon {
  color: rgba(0, 144, 193, .85) !important;
  ;
}

.projectAdd-clanica-background {
  background: rgba(0, 0,   0, 0.03);
}

.projekti-sodelujoce-clanice_background {
  background: rgba(0, 0, 0, 0.03);
}

.projekt-status-modal-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: none;
}

.projectiDetails-buttonWidth {
  width: 45%;
}

.align-center-col{
  height: 80vh;
}

.loginTitle{
  font-size: 27px;
  color: $primary
}

.loginLine{
  border-width: 2px;
  margin-top: 8px;
}

.text-decoration-underline{
  text-decoration: underline;
}

.digitalna-text{
  color: #666
}

.animationTransition-status   {
  opacity: 0;
  animation: show 0.2s ease forwards;
}

@keyframes show {
  from {opacity:0;}
  to {opacity:1;}
} 


