.sidebar {
  min-height: calc(100vh - 56px);
  border-right: 1px solid $color-border;
  transition: all 0.5s ease 0s;

  .sidebar-column {
    height: 100%;
    justify-content: space-between;

    .sidebar-column-hamburger {
      display: none;
    }
  }

  .sidebar-icon {
    color: $color-um-primary;
    font-size: 2rem;
  }

  .sidebar-icon-bottom {
    color: $color-border;
    font-size: 2rem;
  }
  .sidebar-text-bottom {
    color: $color-border;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  span {
    color: $color-um-primary;
    font-weight: 500;
    font-size: 1.11rem;
    padding-left: 0.8rem;
  }
}

.sidebar-big {
  min-width: 250px;
  max-width: 250px;
  text-align: left;
  margin-left: 1rem;
}

.sidebar-small {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  padding: 0;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1435px) {
  .sidebar {
    width: 100%;

    .sidebar-column {
      height: 100%;
      justify-content: space-between;

      .sidebar-column-hamburger {
        display: block;
      }
      .sidebar-icon-hamburger {
        color: white;
      }

      .show-menu-none {
        display: none;
      }

      .show-menu-block {
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        margin-top: 35px;
        margin-right: 26px;
        box-shadow: 0px 1px rgba(0, 0, 0, 0.2);
      }

      .arrow-hamburger-menu__hidden {
        display: none;
      }
    }
  }

  .sidebar-big {
    min-width: 100%;
    min-height: auto;
  }
  .sidebar-small {
    min-width: 100%;
    min-height: auto;
  }
}
