/*HEADER NAV */

.header{
  background: $color-um-primary;
  .navbar-text {
    color: $color-white !important;
  }
  .navbar-brand {
    color: $color-white;
  }
}

.left-header{
  left: 12px;
  padding-top: 0;
  background: white;
}

.right-header {
  top: 26px !important;
  height: 43px;
  right: 15px;
  left: 163px;
}


.environment {
  position: fixed;
  height: 29px;
  top: 69px;
  right: 15px;
  left: 163px;
  z-index: 1031;
  text-align: center;
}

.local {
  background: darkseagreen;
  color:white
}

.dev {
 background: indianred;
  color:white
}

.test {
  background:  	#FFFF99;
}

.sessionModal {
  width:26.5%;
  max-width: 26.5%;
}

.header-tittle{
  letter-spacing: 1px;
  font-size: 22px;
}